import * as fs from "fs";
import * as path from "path";
import * as Mustache from 'mustache';

import { SignatureVars } from './signature-vars';
import { VarsForm } from './vars-form';
// @ts-ignore
import { VARIANTS } from './variants.mjs';

function formSubmitted() {
    // do nothing, updates are realtime
    return false;
}

function formChanged() {
    const rawData = getVarsFromData();
    const signatureData = mapVarsFormToSignatureVars(rawData);
    const signature = renderSignature(signatureData);
    injectSignatureIntoHtml(signature);
}

function injectSignatureIntoHtml(signature: string) {
    const signatureOutput = document.querySelector('#signature-output') as HTMLIFrameElement;
    if (!signatureOutput) {
        throw new Error('Cannot find signature output element');
    }
    const signatureCode = document.querySelector('#signature-code') as HTMLElement;
    if (!signatureCode) {
        throw new Error('Cannot find signature code element');
    }
    signatureOutput.srcdoc = signature;
    signatureCode.textContent = signature;
}

function renderSignature(vars: SignatureVars): string {
    const templateFile = fs.readFileSync(path.join(__dirname, "../dist/template.html"), "utf8")
    return Mustache.render(templateFile, vars);
}

function mapVarsFormToSignatureVars(varsForm: VarsForm): SignatureVars {
    return {
        name: `${varsForm.firstname} ${varsForm.lastname}`,
        position: varsForm.jobtitle,
        phonehref: varsForm.phone.replaceAll(/[^+0-9]/ig, ''),
        phone: varsForm.phone,
        email: varsForm.email,
        timeoff: varsForm.timeoff,
        ...VARIANTS[varsForm.variant],
    };
}

function getVarsForm(): HTMLFormElement {
    const element = document.querySelector('#vars-form') as HTMLFormElement;
    if (!element) {
        throw new Error('Cannot find input form');
    }
    return element!;
}

function getVarsFromData(): VarsForm {
    const data = new FormData(getVarsForm());
    return Object.fromEntries(data.entries()) as unknown as VarsForm;
}

function setupForm() {
    const varsForm = getVarsForm();
    varsForm.addEventListener('submit', formSubmitted);
    varsForm.addEventListener('input', formChanged);
    setTimeout(formChanged, 0);
}

function copyButtonClicked(this: HTMLButtonElement, event: Event) {
    const target = this.getAttribute('for');
    if (!target) {
        throw new Error('Copy button target is not set');
    }
    const copyText = document.querySelector(target) as HTMLElement;
    window.getSelection()!.selectAllChildren(copyText);
    document.execCommand('copy');
}

function iframeCopyButtonClicked(this: HTMLButtonElement, event: Event) {
    const iframe = document.querySelector('#signature-output') as HTMLIFrameElement;
    if (!iframe) {
        throw new Error('Cannot find iframe');
    }
    const copyText = iframe.contentDocument!.querySelector("div") as HTMLElement;
    iframe.contentWindow!.getSelection()!.selectAllChildren(copyText);
    iframe.contentDocument!.execCommand('copy');
}

function setupCopyButtons() {
    for (const button of document.querySelectorAll('.copy-button')) {
        button.addEventListener('click', copyButtonClicked);
    }
    document.querySelector('#iframe-copy-button')!.addEventListener('click', iframeCopyButtonClicked);
}

function setupCodeArea() {
    const codeArea = document.querySelector('#signature-code') as HTMLElement;
    if (!codeArea) {
        throw new Error('Cannot find code area');
    }
    codeArea.addEventListener('paste', function (event) {
        event.preventDefault();
        codeArea.textContent = event.clipboardData!.getData('text/html');
    });
}

window.onload = function () {
    setupForm();
    setupCodeArea();
    setupCopyButtons();
}